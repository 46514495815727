@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    overflow-y: hidden;
}

body {
    font-family: $fontFamily;
    color: $txtColor;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background-color: $bodyBg;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: 100%;
}

ul,
li {
    list-style-type: none;
}
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent; 
}

.mb{
    margin-bottom: $spacing;

    @include for-tablet-portrait-up {
        margin-bottom: $mobileSpacing;
    }
}

.title{
    font-size: $fontSizeMedium;
    font-weight: 700;
    color: $txtGrey
}
.text-bold{
    font-weight: 700;
}

.title-small{
    font-size: $fontSizeSmall;
    font-weight: 700;
    color: $txtGrey
}

.title-large{
    font-size: $fontSizeExtraLarge;
    font-weight: 700;
    color: $txtGrey
}

.txt-success{
    color: $txtGreen;
}
.txt-danger{
    color: $txtRed;
}
.txt-info{
    color: $txtBlue;
}
.txt-warning{
    color: $orange;
}

.clearfix{
    height: 25px;
    width: 100%;
}

.links{
    color: $blue;
    cursor: pointer;
}

.sideModal-overlay{
    z-index: 1000;
    background-color: rgb(0, 0, 0 );
}

.sideModal{
    @include for-tablet-portrait-up {
        width: 100vw !important;
    }
}
.form{
    width: 100% !important;
    display: block;
    align-items: flex-start;
}
.font-10{
    font-size: $font-10;
}
.font-12{
    font-size: $font-12;
}
.font-13{
    font-size: $font-13;
}
.font-14{
    font-size: $font-14;
}
.font-15{
    font-size: $font-15;
}

.font-20{
    font-size: $font-20;
}

.font-30{
    font-size: $font-30;
}
.div-wrapper{
    @include rounded-border;
    @include margin-around;
    @include box-shadow ;
    @include padded-box ;
    background-color: $mainBg !important;
    height: auto;
}

.box-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    i{
        font-size: $font-15;
    }
}

.boxed-div{
    width: 100%;
    height: fit-content;
    border: 1px solid $lightgrey;
    @include rounded-border;
    padding: .5rem;
    margin-bottom: 1rem;
}
.flexy{
    display: flex;
    justify-content: stretch ;

    @include for-tablet-portrait-up {
        display: block;
    }
}
.responsive-img{
    width: 100%;
    height: auto;
    max-height: 250px;
    margin-right: 2rem;
    border-radius: .7rem;
    border: 1px solid $lightgrey;

    @include for-tablet-portrait-up {
        width: 100% !important;
        object-fit: cover;
        margin-bottom: 2rem;
        height: auto;
        max-height: 500px;
    }
}

.row{
    display: flex;
    justify-content: space-between ;

    @include for-tablet-portrait-up {
        display: block;
    }
}
.display-flex{
    display: flex !important;
    width: 100% !important;    
}
.space-between{
    justify-content: space-between;
}

.space-around{
    justify-content: space-around;
}
.space-evenly{
    justify-content: space-evenly;
}
.hidden{
    display: none;
}

.text-info{
    color: $txtBlue;
}
.text-success{
    color: $txtGreen;
}
.text-danger{
    color: $txtRed;
}
.text-white{
    color: $white;
}
.text-primary{
    color: $primary !important;
}
.text-secondary{
    color: $secondary!important;
}