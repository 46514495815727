@use '../../scss/abstracts/' as *;

.footer_container{
    background-color: transparent;
    height: 60px;
    width: fit-content;
    min-width: 100vw;
    bottom: 0 !important;
    position: sticky;
    display: block;
    align-items: center;
    margin-top: 3rem;
    font-size: .9rem;

    &__item_container{
        width: 70%;
        text-align: center;

        @include for-tablet-portrait-up {
            width: 100vw;
        }
    }

    
}