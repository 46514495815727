@use '../scss/abstracts/' as *;

.main{
    padding-left: $sideBarWidth;
    overflow-x: hidden;

    @include for-tablet-portrait-up {
        padding-left: 0;
        width: 100vw;
    }

    &__content{
        min-height: 100vh;
        padding: $spacing;
        transition: all 0.5s ease-in-out;

        @include for-tablet-portrait-up{
            padding: $spacing-sm;
            position: relative;
            background-color: $bodyBg;
            z-index: 100;
        } 
    }
}

.hide_main_content{
    @include for-tablet-portrait-up{
        display: none;
    }
}

.sidebar-open .main__content{
    height: 100vh;
    overflow: hidden;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    transform: scale(0.8) translateX(50%);
    transition: all 0.5s ease-in-out;
}