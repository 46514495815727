@use '../../scss/abstracts/' as *;

.box{
    padding: 20px 30px;
    border-radius: $borderRadius-sm;
    background-color: $mainBg;
    box-shadow: rgb(149, 157, 165, 0.2) 0px 8px 24px;

    &-purple{
        background-color: $primary;
        color: $white;
    }

    &-fullheight{
        height: calc(100% - $spacing);

        @include for-tablet-portrait-up {
            height: calc(100% - $mobileSpacing)
        }
    }
}