@use '../../scss/abstracts/' as *;

.topnav{
    @include flex(center, space-between);
    margin-bottom: $spacing;

    @include for-tablet-portrait-up{
        margin-bottom: $mobileSpacing;
    }
}

.sidebar-toggle{
    --size: 40px;
    height: var(--size);
    width: var(--size);
    @include flex(center, center);
    cursor: pointer;
    display: none;

    @include for-tablet-portrait-up{
        display: flex;
    }

    i{
        font-size: 2rem;
        color: $mainColor;
    }
}