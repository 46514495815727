@use '../../scss/abstracts/' as *;

.fab_container{
    &__button{
        background-color: $primary;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: none;
        font-size: 1.8rem;
        position: fixed;
        bottom: 4%;
        right: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 5px 5px 10px 1px #b8b8b8; 
        box-shadow: 5px 5px 8px 1px #b8b8b8;
        opacity: 0.7;
        cursor: pointer;
        z-index: 99;

        i{
            color: $orange;            
        }
    }
    &__button:hover{
        opacity: 1;
    }
}