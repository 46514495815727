@use '../../scss/abstracts/' as *;

.checkout-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    @include padded-box;

    @include for-tablet-portrait-up{
        display: block;
        padding: .5rem;
    }

    h1,h2,h3,h4{
        color: $color-grey-200;
    }
    h4{
        @include thin-border-bottom
    }

    p{
        font-size: $font-10;
    }
    i{
        font-size: .9rem;
    }

    &__customer-info{
        width: 60%;
        height: inherit;
        @include padded-box;

        @include for-tablet-portrait-up{
            width: 100%;
            padding: .5rem;
        }

        &__items{
            padding: 1rem;

            &__billing{
                padding: 1rem 2rem 0 1rem; 
                display: flex;
                justify-content: space-between;
                @include thin-border-bottom
            }
            &__shipping{
                input{
                    width: 100%;
                }
            }
        }
    }
    &__cart-summary{
        width: 40%;
        height: inherit;
        @include padded-box;
        @include thin-border;

        @include for-tablet-portrait-up{
            width: 100%;
            padding: .5rem;
        }

        &__item{
            padding: 1rem;

            &__product{
                padding: .5rem;
                @include thin-border-bottom; 

                &__summation{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}
.menuList{
    display: flex;
    width: 100%;
    overflow-y: scroll;
    
    &__links{
        border: 1px solid $orange;
        @include rounded-border;
        @include padded-box-h;
        margin: 0 1rem 0 0;
        font-size: .8rem;
    }
}
.active_menu{
    background-color: $orange;
    font-weight: bold;
    color: $white;
}