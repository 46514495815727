$bodyBg: #F3F4FA;
$mainBg: #fff;
$mainColor: #006838;
$purple: #006838;
$lightpurple: #8cb23e;
$orange: #f26522;
$white: rgb(247, 247, 247);
$grey: rgb(206, 206, 206);
$lightgrey: rgb(233, 233, 233);
$black: #000;
$green: #38b818;
$blue: #15bcda;

$primary: #006838;
$primaryLight: #00a558;
$primaryDark: #01381e;

$secondary: #8cb23e;
$secondaryLight: #afdf50;
$secondaryDark: #5e772c;

// color shades
// red
$red: #ef403d;
$color-red-100: #fce5e4;
$color-red-200: #f0a9a3;
$color-red-300: #f18989;
$color-red-400: #e57373;
$color-red-500: #ef5350;

// grey
$color-grey: #4E4D4F;
$color-grey-100: #b2afb4;
$color-grey-200: #7c7b7e;
$color-grey-300: #4E4D4F;
$color-grey-400: #303031;
$color-grey-500: #9e9e9e;
$color-grey-600: #757575;
$color-grey-700: #616161;
$color-grey-800: #424242;
$color-grey-900: #212121;

// green
$color-green-light: #acd146;
$color-green: #a6ce38; // Base color
$color-green-dark: #9dc530;

//dark
$color-dark: #000;
$color-dark-100: #111;
$color-dark-200: #222;
$color-dark-300: #333;
$color-dark-400: #444;
$color-dark-500: #555;

// Default-font-size
$default-font-size: 1.3rem;

//font 10px / 1rem
$font-10: 1rem;

//font 11px / 1rem
$font-11: 1.05rem;

//font 12px / 1rem
$font-12: 1.1rem;

//font 13px / 1rem
$font-13: 1.2rem;

//font 14px / 1rem
$font-14: 1.3rem;

//font 15px / 1rem
$font-15: 1.4rem;

//font 16px / 1rem
$font-16: 1.6rem;

//font 18px / 1rem
$font-18: 1.8rem;

//font 20px / 2rem
$font-20: 2rem;

//font 25px / 2rem
$font-25: 2.4rem;

//font 30px /3rem
$font-30: 3rem;

//font 40px / 4rem
$font-40: 4rem;

//font 50px / 5rem
$font-50: 5rem;

// text colors
$txtColor: #4E4D4F;
$txtGrey: #999797;
$txtPurple: #8624DB;
$txtRed: #DB190C;
$txtBlue: #15bcda;
$txtGreen: #4CAF50;

$fontFamily: 'Roboto', sans-serif;

$fontSizeSmall: 1rem;
$fontSizeNormal: 1.125rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;
$fontSizeExtraLarge: 1.6rem;

$sideBarWidth: 225px;
$sideBarLogoHeight: 171px;
$spacing: 25px;
$spacing-sm: 15px;
$mobileSpacing: 24px;
$borderRadius: 30px;
$borderRadius-sm: 10px;

$mobile-width: 600px;
$tablet-width: 1366px;
