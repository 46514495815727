@use '../../scss/abstracts/' as *;

.progress-bar{
    height: 7px;
    background-color: $bodyBg;
    border-radius: 5px;

    &__inner{
        border-radius: 5px;
        height: 100%;
    }
}
.red_bg{
    background-color: $red
}
.green_bg{
    background-color: $primaryLight
}