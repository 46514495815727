@use '../../scss/abstracts/' as *;

.user-info{
    @include flex(center, flex-start);
    background-color: $mainBg;
    border-radius: $borderRadius;
    height: 40px;
    width: max-content;

    &__img{
        --size: 55px;
        width: var(--size);
        height: var(--size);
        border-radius: $borderRadius;

        img{
            border-radius: $borderRadius;
        }
    }

    &__name{
        padding: 0 $spacing;
        font-size: $fontSizeSmall;
        font-weight: 700;

        @include for-tablet-portrait-up{
            padding: 0 $mobileSpacing;
        }
    }
}