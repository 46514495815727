@use './variables' as *;

@mixin flex($alignItem: center, $justifyContent: center) {
    display: flex;
    align-items: $alignItem;
    justify-content: $justifyContent;
}

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media screen and (max-width: 899px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media screen and (max-width: 1199px) { @content; }
  }
  @mixin for-desktop-up {
    @media screen and (max-width: 1799px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }


@mixin mobile {
    @media only screen and (max-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}
@mixin thin-border {
  border: 1px solid $lightgrey;
  border-radius: 8px; 
}
@mixin thin-border-bottom {
  border-bottom: 1px solid $lightgrey;
}
@mixin thin-border-top {
  border-top: 1px solid $lightgrey;
}

@mixin rounded-border {
  border-radius: 7px;
}
@mixin margin-around {
  margin: .1rem;
}

@mixin profile-pic {
  border-radius: 50%;
}
@mixin padded-box {
  padding: 2rem 2rem;
}
@mixin padded-box-1 {
  padding: 1rem 2rem;
}
@mixin padded-box-h {
  padding: .5rem .5rem;
}
@mixin box-shadow {
  -webkit-box-shadow: 0px 0px 8px -1px #c7c7c7; 
  box-shadow: 0px 0px 8px -1px #c7c7c7;
}