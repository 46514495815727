@use "../../scss/abstracts/" as *;

.container{
    position: fixed;
    width: 100vw;
    background-color: $white;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;

    &__panels-container{
        position: absolute;
        height: 150%;
        top: 40vh;
        left: -1160px;
        background-color: $primary;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        width: 1600px;

        @include for-tablet-portrait-up{
            top: 80vh;
            left: -300px;
            width: 2000px;
        } 
    }

    &__forms-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        top: 15%;
    }
}
.input-field{
    max-width: 310px;
    width: 100%;
    height: 40px;
    background-color: $lightgrey;
    margin: 10px 0;
    border-radius: $borderRadius;
    display: grid;
    grid-template-columns: 10% 90%;
    padding: 0 0.4rem;
    position: relative;
}
.input-field i{
    text-align: center;
    line-height: 40px;
    color: $grey;
    font-size: 1.2rem;
}
.input-field input{
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    color: $black;
    font-size: 1rem;
    text-decoration: none !important;
}

.form-footer{
    font-size: 1rem;
    margin: 0.3rem;
}

form{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.logo-container{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.logo-container img{
    width: auto;
    height: 60px;
}