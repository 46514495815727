@use '../../scss/abstracts/' as *;

.loaderWrapper{
    background-color: inherit;
    width: 100vw;
    height: 100vh;
    margin-top: 25%;
    margin-left: 45%;

    i{
        color: $primary;
        font-size: 1rem;
        margin: 0 auto;

        img{
            height: 75px;
        }
    }
}