@use '../../scss/abstracts/' as *;

.dashboard-wrapper{
    @include flex(stretch);

    @include for-tablet-portrait-up{
        flex-direction: column;
    }

    &__main{
        flex-grow: 1;
        padding-right: $sideBarWidth;

        @include for-tablet-portrait-up{
            padding-right: 0;
        }
    }

    &__right{
        width: $sideBarWidth;
        background-color: $mainBg;
        height: 100vh;
        padding: $spacing-sm;
        position: fixed;
        top: 0;
        right: 0;
        border-bottom-left-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        @include for-tablet-portrait-up{
            position: relative;
            padding: $mobileSpacing;
            margin-top: $mobileSpacing;
            border-radius: $borderRadius-sm;
            width: 100%;
            height: max-content;
        }
    }
}