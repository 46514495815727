@use '../../scss/abstracts/' as *;

.search {
    width: 100%;
    position: relative;
    display: flex;
  }
  
  .searchInput {
    width: 100%;
    border: 1px solid $lightgrey;
    border-right: none;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: $grey;
  }
  
  .searchInput:focus{
    color: $black
  }
  
  .searchButton {
    width: 40px;
    height: 36px;
    border: 1px solid $lightgrey;
    background: $color-grey-100;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    width: 100%;
    right: 0;
  }