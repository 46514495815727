@use '../../scss/abstracts/' as *;

.table-container{
    @include box-shadow;
    @include rounded-border;
    // @include margin-around;
    min-height: 200px;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    padding: 0px 15px;
    background-color: $mainBg;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 00.9rem;

    &__body{
        width: inherit;
    }
    &__header{
        align-items: center;
    }
    &__footer{
        border-top: .5px solid $mainBg;
        align-items: center;
    }
    &__header, &__footer{
        height: 70px;
        display: flex;
        color: $txtGrey;
        justify-content: space-between;
    }
    
    .responsive-table {
      width: 100%;
      tr{
        text-align: left;
        border-radius: 3px;
        padding: 10px;
        margin-bottom: 5px;
      }
      tr td:last-child{
        text-align: right;
      }
      .table-header {
        letter-spacing: 0.01em;
        font-weight: 400;
      }
      td {
        border-top: .5px solid $color-red-100;
      }
      .table-row {
        border-top: .5px solid $color-red-100;
        color: $txtGrey;
        height: 45px;
      }
      .table-row:hover{
        background-color: $color-red-100;
        color: $txtColor;
        font-weight: 400;
      }
      .col-1 {
        flex-basis: 10%;
      }
      .col-2 {
        flex-basis: 40%;
      }
      .col-3 {
        flex-basis: 25%;
      }
      .col-4 {
        flex-basis: 25%;
      } 
      
      @include for-tablet-portrait-up{
        tr td:nth-child(1) {
          border:none; 
        }
        .table-header {
          display: none;
        }
        .table-row{
          border: .5px solid $grey;
          @include rounded-border;
          height: auto;
          justify-content: space-between;
        }
        tr {
          display: block;
        }
        .col {
          width: 100%;
          display: flex;
          padding: 10px 0;
          flex-basis: 100%;  
          &:before {
            color: $color-grey-200;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 50%;
            text-align: left;
            font-weight: 500;
          }          
        }
      }
    }
}

.pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrows{
  cursor: pointer;
  padding: 2px;
  margin: 5px;
  @include rounded-border
}
.arrows:hover{
  background-color: $lightgrey;
}