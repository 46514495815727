@use '../../scss/abstracts/' as *;
  
  /* select starting stylings ------------------------------*/
  .select {
      position: relative;
      width: 100%;
      margin-bottom: 2.5rem;
  }
  
  .select-text {
      position: relative;
      font-family: inherit;
      background-color: transparent;
      width: 100%;
      height:50px;
      padding: .2rem 1rem;
      font-size: .8rem;
      color: $color-grey-200;
      border: 1px solid $grey;
      @include rounded-border;
  }
  
  /* Remove focus */
  .select-text:focus {
      outline: none;
  }
  
      /* Use custom arrow */
  .select .select-text {
      appearance: none;
      -webkit-appearance:none
  }
  
  .select:after {
      position: absolute;
      top: 18px;
      right: 10px;
      /* Styling the down arrow */
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid rgba(0, 0, 0, 0.12);
      pointer-events: none;
  }
  
  
  /* LABEL ======================================= */
  .select-label {
      color: $color-grey-200;
      font-size: 1rem;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 1rem;
      top: 10px;
      transition: 0.2s ease all;
      padding: 0 .2rem;
      background-color: white;
  }
  
  /* active state */
  .select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
      color: $color-grey-200;
      top: -12px;
      transition: 0.2s ease all;
      font-size: .9rem;
  }
  
  /* BOTTOM BARS ================================= */
  .select-bar {
      position: relative;
      display: block;
      width: inherit;
  }
  
  .select-bar:before, .select-bar:after {
      content: '';
      height: 1px;
      width: 0;
      bottom: 1px;
      position: absolute;
      transition: 0.2s ease all;
  }
  
  .select-bar:before {
      left: 50%;
  }
  
  .select-bar:after {
      right: 50%;
  }
  
  /* active state */
  .select-text:focus ~ .select-bar:before, .select-text:focus ~ .select-bar:after {
      width: 50%;
  }
  
  /* HIGHLIGHTER ================================== */
  .select-highlight {
      position: absolute;
      height: 60%;
      width: 100px;
      top: 25%;
      left: 0;
      pointer-events: none;
      opacity: 0.5;
  }