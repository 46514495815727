@use '../../scss/abstracts/' as *;

.summary-box{
    @include flex(stretch, flex-start);

    &__chart{
        width: 70%;

        &__value{
            margin-top: -10%;
            font-weight: 700;
        }
    }

    &__info{
        width: 70%;
        @include flex(flex-start, space-between);
        flex-direction: column;

        &__title{
            font-size: $fontSizeSmall;
            font-weight: 700;
            line-height: 1.2;

            span{
                font-size: 13px;
                font-weight: 400;
            }
        }
        &__value{
            font-weight: 700;
            font-size: $fontSizeSmall;
            color: $black;
            margin-top: .5rem
        }
    }

}

.summary-box-special{
    @include flex(center, space-between);
    flex-direction: column;
    height: 100%;

    &__title{
        font-weight: 700;
        font-size: $fontSizeMedium;
    }

    &__value{
        font-weight: 700;
        font-size: $fontSizeLarge;
    }
}